export const unexpectedErrorMessage = "Une erreur inattendue s'est produite. Veuillez réessayer ultérieurement.";

/* Reads sheet file */
export async function handleSheetFileAsync(e) {
    const file = e.target.files[0];
    if (file != undefined) {
        const fileExtension = file.name.split(".").pop();
        if (fileExtension != "csv" && fileExtension != "xlsx") {
            swal(
                "L'extension de fichier n'est pas prise en charge",
                "Veuillez vous assurer que l'extension du fichier est valide",
                "error"
            );
            return false;
        }

        const data = await file.arrayBuffer();
        let workbook = XLSX.read(data, {cellDates: true});
        return workbook;
    }
    return undefined;
}

export function printPDF(url) {
    let pdfFrame = document.body.appendChild(document.createElement("iframe"));
    pdfFrame.style.display = "none";
    pdfFrame.src = url;
    pdfFrame.onload = () => void pdfFrame.contentWindow.print();
    return pdfFrame;
}

/**
 * Sends an ajax request
 */
export function sendRequest(url, method = 'GET', data = null, showLoader = false, withUploads = false) {
    // Clear old errors if any
    clearErrorMessages();

    let props = {
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
        type: method,
        url: url,
        data: data,
    };

    // Show loader on submit
    // Hide it on complete
    if (showLoader && $('#inProgressModal').length > 0) {
        $('#inProgressModal').modal('show');
        Object.assign(props, {
            complete: () => {
                setTimeout(() => $('#inProgressModal').modal('hide'), 500)
            }
        })
    }

    // If request uploads files
    // Add some props so it doesnt
    // process the contents
    if (withUploads) {
        Object.assign(props, {
            processData: false,
            contentType: false
        })
    }

    return $.ajax(props)

}

export const showErrorMessages = async (response, messagesContainerSelector = null) => {
    console.log(response);
    let errors = [];
    let messagesContainer = messagesContainerSelector ?? '.validation-messages';


    if ((response.status != 422 && response.status != 400) || !response.hasOwnProperty('responseJSON')) {
        alert(unexpectedErrorMessage);
        return;
    }

    if (response.responseJSON.hasOwnProperty('errors')) {
        for (let key in response.responseJSON.errors) {
            let errorMessage = response.responseJSON.errors[key];
            errors.push(errorMessage);
        }

    } else if (response.responseJSON.hasOwnProperty('message')) {
        errors.push(response.responseJSON.message);
    }


    const showErrors = errors.forEach((error) => {
        $("<li>").text(error).appendTo(messagesContainer);
    });

    $.when(showErrors).done(function () {
        $(messagesContainer).slideDown(500);
    });

    // Scroll the window to the validation messages container
    $("html, body").animate(
        { scrollTop: $(messagesContainer).offset().top - 200},
        50,
        "linear"
    );
};

/**
 * Clear validation errors from the page
 */
export const clearErrorMessages = () => {
    if ($(".validation-messages").length > 0) {
        $(".validation-messages").slideUp(200, function () {
            $(this).empty();
        });
    }
};

/**
 * Validates inputs inside of a parent element
 *
 * @param {string} parentElemId
 * @returns
 */
export function checkInputsValidity(parentElemId) {
    let inputsAreValid = true;
    let parent = $("#" + parentElemId);

    let firstInvalidInput;
    $(parent)
        .find("input:visible")
        .each(function () {
            const input = this;

            if (!input.checkValidity()) {
                $(input).addClass("is-invalid");
                inputsAreValid = false;

                if (firstInvalidInput == undefined) {
                    firstInvalidInput = input;
                }
            } else {
                $(input).removeClass("is-invalid");
            }
        });

    // Highlight first invalid input
    if (!inputsAreValid) {
        $(firstInvalidInput).focus();
    }

    return inputsAreValid;
}

/**
 * Validates form data
 *
 * @param {string} formId
 * @returns {boolean}
 */
export function isFormValid(formId) {
    let isFormValid = true;
    let form = document.getElementById(formId);
    let formElements = Array.from(form.elements);

    let firstInvalidElement;
    formElements.forEach((element) => {
        if ($(element).is(":visible")) {
            if (!element.checkValidity()) {
                isFormValid = false;

                if (firstInvalidElement == undefined) {
                    // used for highlighting
                    firstInvalidElement = element;
                }
            }
        }
    });

    $("#" + formId).addClass("was-validated");

    // Highlight first invalid element
    if (!isFormValid) {
        $(firstInvalidElement).focus();
    }

    return isFormValid;
}

export function getUserTransactionsSum() {
    if ($("#userTransactionsSum").length != 0) {
            $("#userTransactionsSum").html(`
                <div class="spinner-grow spinner-grow-sm text-light" role="status">
                </div> DA
            `);

            $.ajax({
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
                },
                type: "GET",
                url: route('backend.event.payment-transactions.getUserTransactionsSum', userId),
                success: function (response) {
                    let data = response.data;

                    $("#userTransactionsSum").html(formatMoney(data.totalTransactionsSum));

                    new bootstrap.Popover("#userTransactionsSum", {
                        title:"Somme des transactions",
                        content: `
                            <b>Espéce :</b> ${formatMoney(data.cashTransactionsSum)}</br>
                            <b>Virement :</b> ${formatMoney(data.transferTransactionsSum)}</br>
                            ${data.isCibEnabled
                                ? `<b>CIB / EDAHABIA :</b> ${formatMoney(data.cibTransactionsSum)}</br>`
                                : ''
                            }
                            <hr class="my-2">
                            <b>Total :</b> ${formatMoney(data.totalTransactionsSum)}</br>
                        `,
                        placement: 'bottom',
                        trigger: 'hover focus',
                        container: 'body',
                        html: true,
                        customClass: 'transactions-popover shadow'
                    });
                }
            })

    }
}

export function deleteItem(
    deleteUrl,
    confirmationTitle = "Vous êtes sûr ?",
    confirmationMessage = "Êtes-vous sûr de vouloir supprimer cet élément ?",
    successMessage = "L'élément a été supprimé avec succès !",
    icon = "warning"
) {
    swal({
        title: confirmationTitle,
        text: confirmationMessage,
        icon: icon,
        buttons: {
            confirm: {
                text: "Oui",
                closeModal: false,
            },
            cancel: "Non",
        },
        dangerMode: true,
    }).then(function (value) {
        if (value != null) {
            sendRequest(deleteUrl, "DELETE")
            .done((response) =>
                swal({
                    title: successMessage,
                    text: "",
                    icon: "success",
                    timer: 1500,
                }).then(() => {
                    window.location = response.redirectUrl ?? window.location.href;
                })
            )
            .fail((response) => showErrorMessages(response));
        }
    });
}


export function resetForm(formId) {
    $('#'+formId)[0].reset()
    $('#'+formId).removeClass('needs-validation was-validated')
    clearErrorMessages(
        $('#'+formId).find('ul', '.validation-messages').attr('id')
    )
}

export function resetFormElements(formId, contextId = null) {
    formId = formId.replace("#", "")


    if (contextId != null) {
        contextId = contextId.replace("#", "")
        $("#"+formId, "#"+contextId)[0].reset();
    } else{
        $("#"+formId)[0].reset();
    }

    let formElements = Array.from(
        document.getElementById(formId).elements
    );

    //Enable all form elements
    formElements.forEach(element => {
        $(element).prop('disabled', false)
    });
}


export function setImagePreview(imageInputId, imagePreviewId) {
    $('#'+imageInputId).change(function() {
        let [file] = this.files;
        [file] = this.files;
        if (file) {
            $('#'+imagePreviewId).attr('src', URL.createObjectURL(file))
        }
    })
}

export const fileLinkComponent = ((filePath) => {
    return `<a href="${filePath}" target="_blank"><i class="fa-solid fa-arrow-up-right-from-square"></i> Ouvrir le fichier</a>`
})


export const loader = $(`
    <div
    class="loading"
    style="
    background: #00000047;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;"></div>
    <div class="spinner-border text-primary loading" style="
    position: absolute;
    top: 50%;
    left: 50%;"
    role="status"></div>
`)

/**
 *
 * @param {*} message
 * @param {*} isDangerMode
 * @param {*} status info|warning|error
 * @returns {bool}
 */
export async function confirmAction(message, isDangerMode = false, status = 'info', description = '') {
    let isConfirm;
    await swal({
        title: message,
        text: description,
        icon: status,
        buttons: {
            confirm: "Oui",
            cancel: "Non",
        },
        dangerMode: isDangerMode
    }).then((value) => {
        if (value == null) {
            isConfirm = false;
        } else {
            isConfirm = true;
        }
    })

    return isConfirm;
}

export const formatMoney = (value) => new Intl.NumberFormat('fr-DZ', {style: 'currency', currency: 'DZD'}).format(value);

export function groupBy(array, key) {
    return array.reduce((acc, item) => {
        const key = typeof key === 'function' ? key(item) : item[key];

        if (!acc[key]) {
            acc[key] = [];
        }

        acc[key].push(item);

        return acc;
    }, {});
}


export function showAlert(type = 'info', title, description = '') {
    return swal(
        title,
        description,
        type
    );
}
